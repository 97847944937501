import React, { useState, useEffect } from 'react';
import { Badge, Typography, Carousel, Image, Button, Modal } from 'antd';
import { AlertFilled, DashboardFilled, SlidersFilled } from '@ant-design/icons';
import tickAsset from '../../assets/tick.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Notification } from '../../components';
import home from '../../assets/banner.jpg';
import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import videoFile from '../../assets/video.mp4'; // 引入视频文件

const arrayText = [
  '097***4 đã rút 1.135.000.000 đ',
  '033***8 đã rút 30.000.000 đ',
  '087***3 đã rút 45.671.000 đ',
  '092***1 đã rút 100.000.000 đ',
  '035***3 đã rút 7.000.000 đ',
  '087***7 đã rút 10.080.000.000 đ',
  '036***9 đã rút 79.095.000 đ',
  '097***2 đã rút 1.000.000.000 đ',
  '096***3 đã rút 100.000.000.000 đ',
  '082***4 đã rút 7.000.000 đ',
  '082***8 đã rút 92.200.000 đ',
  '097***8 đã rút 5.000.000.000 đ',
];

export default function Home() {
  const history = useHistory();
  const { profile } = useSelector((state) => state._auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videoVisible, setVideoVisible] = useState(false); // 控制视频弹窗的显示

  // 动态加载在线客服脚本
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'chatway';
    script.async = true;
    script.src = 'https://cdn.chatway.app/widget.js?id=U9Dj7m61jAL8';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < arrayText.length - 1)
        setCurrentIndex((prev) => prev + 1);
      else setCurrentIndex((prev) => 0);
    }, 1800);
    return () => clearInterval(interval);
  });

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="container"
    >
      <div className="header-content">
        <div>
          <Typography.Text className="text-head">Xin chào, </Typography.Text>
          <br />
          <Typography.Text className="text-head">
            {profile?.kyc?.name || profile.phone}
          </Typography.Text>
        </div>
        <motion.div
          whileTap={{ scale: 0.9, opacity: 0.7 }}
          onClick={() => history.push('/notifications')}
        >
          <Notification />
        </motion.div>
      </div>

      <div className="noti-text-container">
        <Typography.Text className="noti-text">
          {arrayText[currentIndex]}
        </Typography.Text>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', minHeight: 150 }}>
        <Image
          preview={false}
          src={home}
          width={'90%'}
          style={{ borderRadius: 10 }}
        />
      </div>

      <Button
        className="choose-btn"
        onClick={() => {
          history.push('/vay');
        }}
      >
        <Typography.Text
          style={{ color: '#fff', fontWeight: 'bold', fontSize: 20 }}
        >
          Báo cáo lừa đảo
        </Typography.Text>
      </Button>

      <div style={{ padding: 20 }}>
        {/* 视频播放按钮 */}
        <Tab
          title={'Ngăn chặn lừa đảo trực tuyến'}
          icon={<AlertFilled style={{ fontSize: 20, color: '#ff7323' }} />}
          onClick={() => setVideoVisible(true)} // 点击时显示视频弹窗
        />
        <Tab
          title={'Số tiền thu hồi được lên tới 100 tỷ đồng'}
          icon={<DashboardFilled style={{ fontSize: 20, color: '#008f50' }} />}
        />
        <Tab
          title={'Báo cáo Đang chờ điều tra bằng chứng'}
          icon={<SlidersFilled style={{ fontSize: 20, color: '#006997' }} />}
        />
      </div>

      {/* 视频播放器弹窗 */}
      <Modal
        visible={videoVisible}
        onCancel={() => setVideoVisible(false)}
        footer={null}
        width="80%"
        bodyStyle={{ padding: 0 }}
        centered
      >
        <div style={{ position: 'relative', paddingTop: '56.25%' }}> {/* 16:9 比例 */}
          <ReactPlayer
            url={videoFile} // 使用引入的视频文件
            controls
            width="100%"
            height="100%"
            style={{ position: 'absolute', top: 0, left: 0 }}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload', // 禁止下载
                },
              },
            }}
          />
        </div>
      </Modal>

      <Carousel autoplay autoplaySpeed={2800} className="carousel-container">
        <div>
          <div className="carousel slider2">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider3">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider4">
            <div className="sl-text-container"></div>
          </div>
        </div>
      </Carousel>

      <div
        style={{
          paddingTop: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Image src={tickAsset} preview={false} style={{ maxWidth: 100 }} />
        <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#555',
            textAlign: 'center',
          }}
        >
          &reg; Bản quyền thuộc về Ngân Hàng Nhà Nước Việt Nam
          <br />
        </Typography.Text>
      </div>
    </motion.div>
  );
}

const Tab = ({ title, onClick, icon }) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.96,
        opacity: 0.3,
        border: '1px solid #006997',
      }}
      onClick={onClick}
      style={{
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px 15px',
        border: '1px solid #006997',
        borderRadius: 5,
      }}
    >
      <Typography.Text
        style={{ flex: 1, fontSize: 16, color: '#006997', fontWeight: 500 }}
      >
        {title}
      </Typography.Text>
      {icon}
    </motion.div>
  );
};