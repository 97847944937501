import React, { useState } from 'react';
import { CreditCard } from '../../components';
import { motion } from 'framer-motion';
import { Input, Select, Typography, Image, message, Button } from 'antd';
import { GlobalOutlined, UserOutlined } from '@ant-design/icons';
import _bank from './bank.json';
import InputMask from 'react-input-mask';

export default function Three({ onOk }) {
  const [state, setState] = useState({
    bankName: '',
    logo: '',
    number: '',
    name: '',
    date: '',
    manualBankName: '', // 新增：手动输入的银行名称
  });

  // 处理银行选择
  const handleBankChange = (value) => {
    const [bankName, logo] = value.split('@');
    setState({
      ...state,
      bankName,
      logo,
      manualBankName: '', // 清空手动输入
    });
  };

  // 处理手动输入银行名称
  const handleManualInput = (e) => {
    setState({
      ...state,
      manualBankName: e.target.value,
      bankName: e.target.value, // 将手动输入的值同步到 bankName
      logo: '', // 清空 Logo
    });
  };

  // 确认提交
  async function onConfirm() {
    if (!state.bankName || !state.name || !state.number) {
      message.error('Vui lòng cung cấp đầy đủ thông tin yêu cầu');
      return;
    }
    onOk({ bank: state });
  }

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="bank-form-container"
    >
      <Typography.Text strong style={{ fontSize: 17, padding: 10 }}>
        Thông tin ngân hàng thụ hưởng
      </Typography.Text>

      {/* 银行卡信息展示 */}
      <CreditCard
        logoBank={state.logo}
        name={state.name}
        bankNumber={state.number}
        nameBank={state.bankName}
        bankDate={state.date}
      />

      <div style={{ padding: 20 }}>
        {/* 输入银行卡号 */}
        <Input
          size="large"
          placeholder="Số tài khoản"
          type="number"
          prefix={<GlobalOutlined className="information-icon" />}
          value={state.number}
          onChange={(e) => setState({ ...state, number: e.target.value })}
          className="bank-input"
        />

        {/* 输入持卡人姓名 */}
        <Input
          value={state.name}
          onChange={(e) => setState({ ...state, name: e.target.value })}
          size="large"
          placeholder="Tên chủ tài khoản"
          prefix={<UserOutlined className="information-icon" />}
          className="bank-input"
        />

        {/* 银行选择器 */}
        <Select
          className="select-bank"
          placeholder="Chọn ngân hàng thụ hưởng"
          onChange={handleBankChange}
          value={state.bankName} // 绑定选择的银行或手动输入的值
        >
          {_bank.banksnapas.map((item) => (
            <Select.Option key={item.bankId} value={`${item.shortName}@${item.logo}`}>
              <div>
                <Typography.Text strong>{item.shortName}</Typography.Text> -{' '}
                <Typography.Text>{item.vn_name}</Typography.Text>
              </div>
              {item.logo && (
                <Image src={item.logo} preview={false} width="20%" />
              )}
            </Select.Option>
          ))}
        </Select>

        {/* 手动输入银行名称 */}
        <Input
          placeholder="Hoặc nhập tên ngân hàng thủ công"
          value={state.manualBankName}
          onChange={handleManualInput}
          style={{ marginTop: 10 }}
        />

        {/* 确认按钮 */}
        <div className="send-request-div">
          <Button className="confirm-btn" onClick={onConfirm}>
            <Typography.Text className="confirm-div-title">
              Gửi yêu cầu
            </Typography.Text>
          </Button>
        </div>
      </div>
    </motion.div>
  );
}
