import { Button, Modal, Typography } from 'antd';
import { useState } from 'react';
import moment from 'moment';

export default function App({ data, profile }) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Typography.Link onClick={() => setVisible(true)}>
        điều khoản dịch vụ
      </Typography.Link>
      <Modal
        visible={visible}
        onOk={() => setVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable
        onCancel={() => setVisible(false)}
      >
        <center>
          <Typography.Text strong style={{ fontSize: 20 }}>
            {' '}
            Quy định chung
          </Typography.Text>
        </center>
        <Typography.Paragraph>
          Người nước ngoài đăng kí ngân hàng nhà nước việt nam không nhận được sự bảo vệ của ngan hàng nhà nước Việt nam xẽ xóa các khoản tiền nghi ngờ về gian lận
        </Typography.Paragraph>
        <Typography.Paragraph>
          NGÂN HÀNG NHÀ NƯỚC VIỆT NAM sẽ xác minh thông tin do công dân cung cấp . thông tin cung cấp cang nhiều , bộ phận A05 sẽ đóng băng các tài khoản ngân hàng nghi ngờ gian lận càng nhanh . tránh tình trạng tiền bị chuyển ra nước ngoài không thể lấy lại được .
        </Typography.Paragraph>
        <Typography.Paragraph>
          Bất kì thông tin sai lệch , giả mạo hoặc không rõ ràng nào cũng có thể dẫn đến tiền bị lừa đảo không thể lấy lại được ,
          Tất cả những thông tin sai lệch giả mạo để yêu cầu NGÂN HÀNG NHÀ NƯỚC VIỆT NAM trợ giúp đòi lại tiền đều sẽ phải chịu trách nhiệm trước pháp luật . Những bộ phận liên quan sẽ gửi hồ sơ vụ án cho tòa án để khởi kiện vì lừa đảo .
        </Typography.Paragraph>
        <Typography.Paragraph>
          Để được hỗ trợ, vui lòng liên hệ với người phụ trách phòng Thu hồi Quỹ của NGÂN HÀNG QUỐC GIA
        </Typography.Paragraph>
      </Modal>
    </>
  );
}
