import { Button, Modal, Typography } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import conDau from '../assets/asign.jpg'

export default function App({ data, profile }) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        shape="round"
        style={{ background: '#ff7323', margin: 10 }}
        onClick={() => setVisible(true)}
      >
        <Typography.Text style={{ color: '#fff' }} strong>
          Kiểm tra thông tin vụ án
        </Typography.Text>
      </Button>
      <Modal
        visible={visible}
        onOk={() => setVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable
        onCancel={() => setVisible(false)}
      >
        <h5>
          <center>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</center>
        </h5>
        <h5>
          <center>ĐỘC LẬP - TỰ DO - HANH PHÚC</center>
        </h5>
        <br />
        <Typography.Text strong>
          <center>Cam kết pháp luật</center>
        </Typography.Text>
        <br />
        <p>
          Bên A (NHNNVN):{' '}
          <Typography.Text strong>
            {' '}
            Ngân Hàng Nhà Nước Việt Nam
          </Typography.Text>
        </p>
        <p>
          Bên B (Người bị hại) Ông / Bà :
          <Typography.Text strong>
            {profile?.kyc?.name || 'Cập nhật khi hoàn thành'}
          </Typography.Text>
        </p>
        <p>
          {' '}
          Số CMT / CCCD :
          <Typography.Text strong>
            {profile?.kyc?.id_number || 'Cập nhật khi hoàn thành'}
          </Typography.Text>
        </p>
        <p>
          Ngày ký :
          <Typography.Text strong>
            {' '}
            {moment(data?.created_at).format('hh:mm A DD/MM/YYYY')}
          </Typography.Text>
        </p>
        <p>
          Số tiền bị lừa :{' '}
          <Typography.Text strong>
            {data?.amount.toLocaleString()} VNĐ
          </Typography.Text>
        </p>
        <p>
          Mã cam kết :{' '}
          <Typography.Text strong>
            {data?.slug || 'Cập nhật khi hoàn thành'}
          </Typography.Text>
        </p>
        <p>
          (Ngân Hàng Nhà Nước Việt Nam ) : phòng hoàn trả tiền nghi ngờ bị lừa đảo 
          
        </p>
        <p>
          1. Bên bị hại không được cung cấp thông tin sai sự thật cho luật sư và ngân hàng nhà nước để yêu cầu trả lại số tiền đã bị lừa đảo.
        </p>
        <p>
          2. Bên B không được gửi thông tin nhận dạng sai lệch lên bộ phận rút tiền, để không ảnh hưởng đến việc trả tiền vào tài khoản của bị hại
        </p>
        <p>
         3. Phòng hoàn trả tiền chỉ chịu trách nhiệm thu hồi và tiến hành hoàn trả số tiền bị lừa đảo.
        </p>
        <p>4. Số tiền hoàn trả được hỗ trợ bởi dữ liệu số tiền theo thời gian thực do các đồng nghiệp từ Cục An ninh công cộng A05 cung cấp và thỏa thuận vẫn có giá trị ràng buộc về mặt pháp lý</p>
        <p>
          5. Bên B có trách nhiệm và nghĩa vụ tuân thủ hướng dẫn của nhân viên Ngân Hàng Nhà Nước. Nếu Bên B không tuân thủ hướng dẫn, gây ra sai sót trong việc thu hồi vốn, Bên B phải tự chịu mọi tổn thất.
        </p>
        <p>
          6. Trường hợp việc rút tiền không thành công do nhiều lý do như CMND, thẻ ngân hàng, mã thực hiện giao dịch không đúng, hệ thống sẽ phát hiện Bên B nghi ngờ có hành vi gian lận hoặc làm giả chứng cứ gian lận. Bên B phải chủ động phối hợp với Ngân hàng Nhà nước Việt Nam để xử lý.
        </p>
        <p>
          7. Nếu bên B từ chối hợp tác, Bên A có quyền khởi kiện ra Tòa án nhân dân và báo cáo lên trung tâm thông tin tín dụng của Ngân Hàng Nhà Nước Việt Nam. Hành vi gian lận bị nghi ngờ của Ngân hàng Nhà nước sẽ được phản ánh trong báo cáo tín dụng , ảnh hưởng đến tín dụng và các khoản vay của Bên B trong tương lai, và sẽ bị hạn chế và trục xuất ở bất kỳ quốc gia nào...
        </p>
        <p></p>
        <p>
          
        </p>
        <p>
         
        </p>
        <p>
         
        </p>
        <p>
           <br />
          
        </p>
        <p>
          
        </p>
        <p>
          
        </p>
        <br />


        <div style={{ display: 'flex', justifyContent: 'center', padding: '0 20px' }}>
          <div>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }} >Bên B</p>
            <img src={`${data?.signature_capture}`} width="180px" />
            <h5 style={{ textAlign: 'center', fontWeight: 'bold' }} >{profile?.kyc?.name}</h5>
          </div>
          <div>
            <p style={{ textAlign: 'center', fontWeight: 'bold', position: 'relative' }} >Bên A : </p>
            <img src={conDau} width="160px" style={{ marginTop: '-20px' }} />
          </div>
        </div>
      </Modal>
    </>
  );
}
